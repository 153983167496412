/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect, Suspense } from "react"
import { RecoilRoot, useRecoilState } from "recoil"
import { Helmet } from "react-helmet"

import PropTypes from "prop-types"

import getFirebase, { FirebaseContext } from "./Firebase"

const Layout = ({ props, children }) => {
    const isSSR = typeof window === "undefined"

    const [firebase, setFirebase] = useState(null)

    useEffect(() => {
        if (!isSSR) {
            const app = import("firebase/app")
            const firestore = import("firebase/firestore")

            Promise.all([app, firestore]).then(values => {
                const firebase = getFirebase(values[0])
                setFirebase(firebase)
            })
        }
    }, [firebase])

    return (
            <RecoilRoot>
            {!isSSR && (
                <FirebaseContext.Provider value={firebase}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                        />
                    </Helmet>

                    <Suspense fallback={<h3>Loading Details...</h3>}>
                        {children}
                    </Suspense>
                </FirebaseContext.Provider>
            )}
        </RecoilRoot>
    )
}

export const LayoutWithFirebase = ({ children }) => {
    return (
        <Layout>
            <FirebaseContext.Consumer>
                {value => <>{children}</>}
            </FirebaseContext.Consumer>
        </Layout>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
